.filt_res_div{
    margin-top: 10px;
}


.fliter_inp_gap{
   margin-left: auto;
   margin-right: auto;

    .form-control{
        width: 300px;
        height: 41px;
    }
}

@media print{
    .print_spaces_page{
        page-break-after: always;
    }
}

@media screen and (max-width: 1380px)  {
    .buutons_filter_res{
        margin-top: 10px;
        margin-left: 0;
    }
    
}

@media screen and (max-width : 1250px) {
  
    .fliter_inp_gap{
        margin-right: 10px;
        margin-left: 0;
    }
    .inp_only_fil{
        margin-left: 0px;
        margin-top: 10px;
    }
}